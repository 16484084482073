#root input {
    padding: 10px 20px;
    margin: 6px 4px;
}

#root select {
    margin: 6px 4px;
    font-size: 18px;
    padding-left: 10px;
}
#root div > label > span, #root div > span > label > span {
    display: inline-block;
    width: 150px;
}

/* not needed anymore ... */

#filings_list li {
    list-style: none;
    padding: 10px;
    border-bottom: 1px solid #aaa;
}

#filings_list table {
    border-spacing: 0;
}





#filings_list table tbody.overview tr td {
    padding: 10px;
    border-bottom: 1px solid #aaa;
}
#filings_list table tbody:nth-last-child(2) tr td {
    padding: 10px;
    border-bottom: none;
}

/*#filings_list tbody.filing_detail tr td:not(:last-child) {
    border-right: 1px solid #aaa;
}*/

#filings_list tbody tr td:first-child {
    min-width: 350px;
}
@media all and (max-width: 1000px){
    #filings_list tbody tr td:first-child {
        min-width: 300px;
    }
}
@media all and (max-width: 800px){
    #filings_list tbody tr td:first-child {
        min-width: 200px;
    }
}
@media all and (max-width: 600px){
    #filings_list tbody tr td:first-child {
        min-width: 0;
    }
}

#filings_list tr:nth-child(odd) {
    cursor: pointer;
}

#filings_list tr td:first-child {
    font-weight: 400;
}

.filing_detail table {
    width: 100%;
}

.filing_detail tr:nth-child(odd):not(:last-of-type) {
    background-color: var(--colorLight20p);
}

.filing_detail td, .filing_detail th {
    padding: 8px;
}

.filing_detail td:first-child {
    padding-left: 24px;
}

.filing_detail td > * {
    margin-right: 10px;
}


div.status {
    padding: 20px;
    margin-bottom: 10px;
}

div.status.success {
    background-color: rgba(0, 255, 0, 0.2);
}

div.status.error {
    background-color: rgba(255, 0, 0, 0.2);
}
div.status.warning {
    background-color: rgba(255, 119, 0, 0.2);
}
div.status.info {
    background-color: rgba(0, 0, 255, 0.2);
}
div.status i.fa {
    float: right;
    cursor: pointer;
}

input[type="file"] {
    line-height: 0;
}

.noliststyle {
    list-style: none;
}

input[type="radio"] {
    appearance: radio;
    line-height: 0;
}

a {
    cursor: pointer;
}
em {
    font-style: normal;
    cursor: pointer;
    color: var(--colorLight);
}


select {
    padding-right: 40px;
}

/*
.select2-results,.select2-results__option {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}
.select2-dropdown--below, .select2-container--default .select2-selection--single  {
    border: 1px solid rgba(110, 161, 154, 1);
}
.select2-choices {
    min-height: 150px;
    max-height: 150px;
    overflow-y: auto;
}
.select2-selection__rendered {
    line-height: 46px !important;
}
.select2-container .select2-selection--single {
    height: 50px !important;
}
.select2-selection__arrow {
    height: 50px !important;
}*/

#root input[type="text"], #root input[type="number"], #root input[type="password"], #root input[type="email"] {
    padding: 6px 12px;
    line-height: 35px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

div > label {
    min-width: 385px;
}
div > label:not(:last-of-type){
    margin-right: 80px;
}

/*
div > label > span {
    text-align: right;
    margin-right: 10px;
}
div > h2 {
    margin-left: 160px;
}*/

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--colorLight);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

table th {
    text-align: left;
    padding-left: 8px;
    border-bottom: 1px solid #aaa;
}

.reactSelectContainer {
    display: inline-block;
/*    min-width: 390px;*/
    min-width: 100px;
    margin-left: 4px;
    margin-bottom: 4px;
    z-index: auto;
}

.fa-angle-right,.fa-angle-down {
    display: inline-block;
    width:15px;
}
.reactContainer {
    padding-left: 25px;
}
.reactContainer > h2 {
    cursor: pointer;
    margin-left: -25px;
}
.reactContainer.sub {
    padding-left: 0;
}
.reactContainer.sub > h2 {
    padding-left: 25px;
}
