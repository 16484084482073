@import "../vars"
#login
  margin: 0 auto
  max-width: 800px

  label
    margin-right: 0!important
    span
      display: inline-block
      width: 0px!important

      text-align: right
      padding: 0px
  input
    display: inline-block
    padding: 20px
    width: 400px
    border: 2px solid $ess_green
    &:active, &:focus
      outline: none

  button
    padding: 12px 16px
    background-color: $ess_green
    cursor: pointer
    margin: 10px
    border: none
    &:active, &:focus
      outline: none

  text-align: center
  img
    margin-top: 10px
    position: absolute

