@import "../_vars"
#subnav
  max-width: 1320px
div.rt-thead
  &.-headerGroups
    display: none !important

  &.-filters
    div.rt-th
      text-align: left

      input
        margin-left: 0px
        line-height: 20px !important

div.status h2

div.rt-resizable-header-content
  text-align: left


  margin-left: 0

div.ReactTable select
  width: 150px

div.popup-content, div.MuiPaper-root
  background-color: white !important
  opacity: 100
  max-height: 80%
  overflow: scroll
  div.closeButton
    display: none
    position: absolute
  &.popup-modal-content, & > div:nth-child(2)
    padding: 20px !important
    border: 2px solid $ess_green !important
    border-radius: 8px
div.MuiPaper-rounded
  border-radius: 8px
#menu
  div.popup-content
    max-height: initial!important



.reactCreatableSelect
  & > div > div > div > div
    margin-left: -34px
  //background-color: red
  input
    margin-left: 35px !important

.reactCreatableSelect
  div
    margin-top: 0px
    margin-bottom: 1px

#files_table

  div.rt-td:first-of-type, div.rt-th:first-of-type
    text-align: center

#finance_authority_information
  margin-bottom: 10px

table.table_tabs
  table-layout: fixed

  tr

    td
      text-align: center

      padding: 10px
      color: white
      border: 2px solid $ess_green
      border-radius: 5px
      transition: background-color .2s ease-in-out
      background-color: $ess_green
      box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.15)

      &:hover
        background-color: $ess_green

      &:active
        box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.15)

      cursor: pointer


      &.active
        border: 1px solid $ess_grey
        background-color: $ess_green

div.reactContainer
  label.switch
    vertical-align: sub
    margin-left: 4px
  h2 b
    font-weight: 400!important
label.maxCheckbox
  line-height: 34px!important
  display: inline-block
  label.switch
    vertical-align: middle



div.company_list
  table tr
    cursor: default!important
  a
    &, &:link, &:visited
      color: $ess_green
      text-decoration: none




div.react-datepicker-wrapper
    display: inline-block!important

label.dateTimeInput div.react-datepicker-wrapper
  margin-left: -4px
.react-datepicker__day--selected, .rbc-event, .rbc-event.rbc-selected, .react-datepicker__time-list-item--selected
  background-color: $ess_green !important

.react-datepicker__day
  &:hover
    background-color: #ddd

button.react-datepicker__navigation
  margin: inherit
  &:hover
    background-color: transparent


.btn
  color: white!important


@media print
  nav, button, .btn
    -webkit-print-color-adjust: exact
  .hideOnPrint
    display: none
  body, *
    font-size: 11pt!important
    h1
      font-size: 18pt!important
    h2
      font-size: 15pt!important
    h3
      font-size: 13pt!important



a.iconLink:hover
  text-decoration: none!important
  & > span
    text-decoration: underline

tbody.overview
  img.flag
    margin-bottom: -4px
    margin-right: 4px
  @keyframes blink
    50%
      opacity: 0.1

  svg.blinking
    animation: blink 1.7s step-start 0s infinite

article.mitte
  max-width: 1280px


li label.switch
  margin-top: 13px
  vertical-align: text-bottom



#react-select-2-input
  max-width: unset!important
.MaxSelect__placeholder
  margin-left: 5px!important
div.tox-promotion
  display: none!important
